:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._col-onSurface--1130999482{color:var(--onSurface--neutral--default);}
:root ._fow-f-weight-1{font-weight:var(--f-weight-1);}
:root ._ls-f-letterSpa779312659{letter-spacing:var(--f-letterSpacing-16);}
:root ._fos-f-size-16{font-size:var(--f-size-16);}
:root ._lh-f-lineHeigh3500599{line-height:var(--f-lineHeight-20);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._fd-row{flex-direction:row;}
:root ._ai-flex-end{align-items:flex-end;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._fos-f-size-14{font-size:var(--f-size-14);}
:root ._lh-f-lineHeigh3500576{line-height:var(--f-lineHeight-18);}
:root ._col-onSurface--412802929{color:var(--onSurface--neutral--defaultAlt);}
:root ._fg-1{flex-grow:1;}
:root ._fs-1{flex-shrink:1;}
:root ._maw-250px{max-width:250px;}
:root ._ta-right{text-align:right;}