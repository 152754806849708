:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._gap-_gtSm_t-space-6{gap:var(--t-space-6);}}
:root ._fd-row{flex-direction:row;}
:root ._ai-center{align-items:center;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pr-_gtSm_0px{padding-right:0px;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pl-_gtSm_0px{padding-left:0px;}}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-onSurface--1130999482{color:var(--onSurface--neutral--default);}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-4{font-weight:var(--f-weight-4);}
:root ._ls-f-letterSpa779312659{letter-spacing:var(--f-letterSpacing-16);}
:root ._fos-f-size-20{font-size:var(--f-size-20);}
:root ._lh-f-lineHeigh3500603{line-height:var(--f-lineHeight-24);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}