:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._btlr-t-radius-2{border-top-left-radius:var(--t-radius-2);}
:root ._btrr-t-radius-2{border-top-right-radius:var(--t-radius-2);}
:root ._bbrr-t-radius-2{border-bottom-right-radius:var(--t-radius-2);}
:root ._bblr-t-radius-2{border-bottom-left-radius:var(--t-radius-2);}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._btc-onSurface--1968954642{border-top-color:var(--onSurface--neutral--outlineAlt);}
:root ._brc-onSurface--1968954642{border-right-color:var(--onSurface--neutral--outlineAlt);}
:root ._bbc-onSurface--1968954642{border-bottom-color:var(--onSurface--neutral--outlineAlt);}
:root ._blc-onSurface--1968954642{border-left-color:var(--onSurface--neutral--outlineAlt);}
:root ._btw-t-space-0--1603{border-top-width:var(--t-space-0--25);}
:root ._brw-t-space-0--1603{border-right-width:var(--t-space-0--25);}
:root ._bbw-t-space-0--1603{border-bottom-width:var(--t-space-0--25);}
:root ._blw-t-space-0--1603{border-left-width:var(--t-space-0--25);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._bg-onSurface--1936048705{background-color:var(--onSurface--neutral--zebra);}
:root ._pt-t-space-3{padding-top:var(--t-space-3);}
:root ._pb-t-space-3{padding-bottom:var(--t-space-3);}
:root ._pr-t-space-6{padding-right:var(--t-space-6);}
:root ._pl-t-space-6{padding-left:var(--t-space-6);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-4{font-weight:var(--f-weight-4);}
:root ._ls-f-letterSpa779312659{letter-spacing:var(--f-letterSpacing-16);}
:root ._fos-f-size-14{font-size:var(--f-size-14);}
:root ._lh-f-lineHeigh3500570{line-height:var(--f-lineHeight-12);}
:root ._col-onSurface--1947560924{color:var(--onSurface--neutral--muted);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._fow-f-weight-1{font-weight:var(--f-weight-1);}
:root ._fos-f-size-16{font-size:var(--f-size-16);}
:root ._lh-f-lineHeigh3500576{line-height:var(--f-lineHeight-18);}
:root ._fd-row{flex-direction:row;}
:root ._gap-t-space-3{gap:var(--t-space-3);}
:root ._pt-t-space-2{padding-top:var(--t-space-2);}
:root ._pr-t-space-2{padding-right:var(--t-space-2);}
:root ._pb-t-space-2{padding-bottom:var(--t-space-2);}
:root ._pl-t-space-2{padding-left:var(--t-space-2);}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._w-10037{width:100%;}
:root ._whiteSpace-nowrap{white-space:nowrap;}
:root ._col-onSurface--1130999482{color:var(--onSurface--neutral--default);}
:root ._maw-10037{max-width:100%;}
:root ._textOverflow-ellipsis{text-overflow:ellipsis;}