:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._pos-absolute{position:absolute;}
:root ._l-5037{left:50%;}
:root ._transform-translateY-511184971{transform:translateY(-22px) translateX(-22px);}
:root ._pt-t-space-3{padding-top:var(--t-space-3);}
:root ._pr-t-space-3{padding-right:var(--t-space-3);}
:root ._pb-t-space-3{padding-bottom:var(--t-space-3);}
:root ._pl-t-space-3{padding-left:var(--t-space-3);}
:root ._bg-onSurface--1936048705{background-color:var(--onSurface--neutral--zebra);}